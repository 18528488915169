<template>
    <div>
        <div class='icon-container'>
            <b-icon :icon='lockIcon' class='lock-icon' @click='toggleLock'></b-icon>
        </div>
        <div class='AB-container'>
            <div class='A-icon split-icon'>A</div>
            <div class='B-icon split-icon'>B</div>
        </div>
        <div class='viewer-divider'></div>
    </div>
</template>

<script>
export default {
  name: 'SplitViewDivider',
  data() {
    return {
      locked: false
    };
  },
  computed: {
    lockIcon() {
      return this.locked ? 'lock' : 'unlock';
    }
  },
  methods: {
    toggleLock() {
      this.locked = !this.locked;
    }
  }
};
</script>


<style scoped>
.icon-container {
    position: relative;
    width: 0;
    height: 0;   
    display: none;
}

.lock-icon {
    position: absolute;
    padding: 0.625rem;
    background: white;
    border-radius: 100%;
    z-index: 100;
    right: -1.313rem;
    top: 0.875rem;
    cursor: pointer;
}

.AB-container {
    position: relative;
    width: 0;
    height: 0;   
}

.split-icon {
    z-index: 100;
    font-weight: bolder;
    color: white;
    background: gray;
    border-top: 0.063rem solid white;
    border-bottom: 0.063rem solid white;
}

.A-icon {
    position: absolute;
    top: 40vh;
    left: -1.75rem;
    border-left: 0.063rem solid white;
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
    padding-left: 0.625rem;
    padding-right: 0.313rem;
}

.B-icon {
    position: absolute;
    top: 40vh;
    right: -2.063rem;
    margin-top: 0rem;
    border-right: 0.063rem solid white;
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
    padding-left: 0.313rem;
    padding-right: 0.625rem;
}

.viewer-divider {
  border-left: 0.375rem solid white;
  height: calc(100vh - 7.75rem);
  margin-top: -1.5rem;
}
</style>